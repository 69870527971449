<template>
  <div class="content">
    <div class="columns">
      <div class="column">
        <h1 class="title is-1">{{ unit.term }}</h1>
      </div>
      <div class="column is-narrow">
        <b-button outlined type="is-primary" class="right" icon-left="check" @click="saveAndClose">Save & Close</b-button>
      </div>
      <div class="column is-narrow">
        <b-button type="is-primary" class="right" icon-left="content-save" @click="save">Save</b-button>
      </div>
    </div>
    <b-field horizontal label="Unit Term">
      <b-input
        v-model="unit.term"
        placeholder="e.g. pinch"
        @input="saveDebounced" />
    </b-field>
    <b-field horizontal label="Plural">
      <b-tooltip label="Leave blank if no plural is needed" position="is-right">
        <b-input
          v-model="unit.plural"
          placeholder="e.g. pinches"
          expanded
          @input="saveDebounced" />
      </b-tooltip>
    </b-field>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import db from "@/firebase/db"

export default {
  name: 'IngredientUnitView',
  props: [
    'id'
  ],
  data() {
    return {
      unit: {},
    };
  },
  created: function () {
    // fetch our unit
    db.collection('ingredient_units')
      .doc(this.id)
      .get()
      .then(snapshot => {
        this.unit = snapshot.data()
      })
  },
  methods: {
    saveDebounced: debounce(function () {
        this.save()
      }, 1000),
    async saveAndClose(){
      this.save()
        .then(() => {
          this.$router.go(-1)
        })
    },
    async save(){
      db.collection('ingredient_units')
        .doc(this.id)
        .set(this.unit)
        .then(() => {
          this.$buefy.toast.open({type: 'is-success', message: `Ingredient unit successfully updated!`})
        })
        .catch((error) => {
          this.$buefy.toast.open({type: 'is-danger', message: `Error saving ingredient unit: ${error}`})
        });
    },
  }
}
</script>
